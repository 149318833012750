import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import ProfPic from "../images/profile.jpg";
import "./header.scss";

const Header = data => {
  const [uri, setUri] = useState("");

  useEffect(() => {
    const uri = document.createElement("a");
    uri.href = window.location.href;
    setUri(uri);
  }, []);

  return (
    <header className="site_header">
      {/* <img
      id="header-image"
      src={ProfPic}
      alt="Vanessa's profile picture"
    /> */}
      {uri.pathname === "/" ||
      uri.pathname === "/playground/" ||
      uri.pathname === "/thoughts/" ||
      uri.pathname === "/contact/" ? (
        <h1 id="title">Vanessa Cassandra</h1>
      ) : (
        <span id="title">Vanessa Cassandra</span>
      )}
      <nav className="header_links">
        <Link to="/" className={uri.pathname === "/" ? "active" : ""}>
          About
        </Link>
        <Link
          to="/playground/"
          className={uri.pathname === "/playground/" ? "active" : ""}
        >
          Playground
        </Link>
        <Link
          to="/thoughts/"
          className={uri.pathname === "/thoughts/" ? "active" : ""}
        >
          Thoughts
        </Link>
        <Link
          to="/contact/"
          className={uri.pathname === "/contact/" ? "active" : ""}
        >
          Contact
        </Link>
      </nav>
    </header>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
