import React from "react"

import {
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
  FaEnvelope,
  FaFacebookF,
} from "react-icons/fa"

import "./footer.scss"

function footer(props) {
  return (
    <footer style={{ textAlign: "right", marginTop: "2rem" }}>
      <div id="social-icons">
        <a
          className="icon"
          href="mailto:vanessa.cassandra.s@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaEnvelope />
        </a>
        <a
          className="icon"
          href="https://www.linkedin.com/in/vanessa-cassandra-0b567b25/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaLinkedinIn />
        </a>
        <a
          className="icon"
          href="https://github.com/vancassa"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaGithub />
        </a>
        <a
          className="icon"
          href="https://twitter.com/vancassa"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaTwitter />
        </a>
        <a
          className="icon"
          href="https://www.facebook.com/vanessa.cassandra.1"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaFacebookF />
        </a>
      </div>
      ©{new Date().getFullYear()}
    </footer>
  )
}

export default footer
